<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Login v1 -->
      <b-card class="mb-0 px-1">
        <b-link class="brand-logo">
          <vuexy-logo />
        </b-link>

        <b-card-title class="mb-1 text-center">
          One Account All of Vue
        </b-card-title>
        <b-card-text class="mb-2 text-center">
          Please login to your account to continue
        </b-card-text>

        <!-- form -->
        <validation-observer ref="loginForm" #default="{invalid}">
          <b-form class="auth-login-form mt-2" @submit.prevent>
            <!-- username -->
            <b-form-group label-for="username" label="Username or Phone">
              <validation-provider
                #default="{ errors }"
                name="Username"
                rules="required|alpha"
              >
                <b-form-input
                  id="username"
                  v-model="username"
                  name="login-username"
                  :state="errors.length > 0 ? false : null"
                  placeholder="john.doe"
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="password">Password</label>
                <b-link>
                  <small>Forgot Password?</small>
                </b-link>
              </div>
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false : null"
                    name="login-password"
                    placeholder="Password"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- checkbox -->
            <b-form-group>
              <b-form-checkbox
                id="remember-me"
                v-model="status"
                name="checkbox-1"
              >
                Remember Me
              </b-form-checkbox>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              type="submit"
              block
              :disabled="invalid || loading"
              @click="login"
            >
              <b-spinner
                v-if="loading"
                small
                type="grow"
              />
              <span v-if="!loading">Login</span>
            </b-button>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <span>New here? </span>
          <b-link>
            <span>Create an account</span>
          </b-link>
        </b-card-text>

        <div v-if="false" class="divider my-2">
          <div class="divider-text">
            or
          </div>
        </div>

        <!-- social button -->
        <div v-if="false" class="auth-footer-btn d-flex justify-content-center">
          <b-button href="javascript:void(0)" variant="facebook">
            <feather-icon icon="FacebookIcon" />
          </b-button>
          <b-button href="javascript:void(0)" variant="twitter">
            <feather-icon icon="TwitterIcon" />
          </b-button>
          <b-button href="javascript:void(0)" variant="google">
            <feather-icon icon="MailIcon" />
          </b-button>
          <b-button href="javascript:void(0)" variant="github">
            <feather-icon icon="GithubIcon" />
          </b-button>
        </div>
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton,
  BForm,
  BFormInput,
  BFormGroup,
  BCard,
  BLink,
  BCardTitle,
  BCardText,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
  BSpinner,
} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    VuexyLogo,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      username: '',
      password: '',
      status: '',
      loading: false,
      // validation rules
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    showToast(variant, title, text, icon) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      },
      { position: 'bottom-right' })
    },
    login() {
      this.loading = true
      this.$store.dispatch('auth/login', {
        username: this.username,
        password: this.password,
      }).then(res => {
        if (res.response) {
          if (res.response.code !== 200) {
            this.loading = true
            if (Array.isArray(res.response.data.data)) {
              res.response.data.data.forEach(err => {
                this.showToast('danger', res.response.data.message, err.message, 'BellIcon')
              })
            } else {
              this.showToast('danger', res.response.data.message, null, 'BellIcon')
            }
          } else {
            throw new Error('Unknown error occurred')
          }
        } else if (res.stack) {
          this.loading = true
          this.showToast('danger', 'Error', res.message, 'BellIcon')
        } else {
          if (res.status !== 200) {
            this.loading = true
            throw new Error('Unknown error occurred')
          }
          if ('access_token' in res.data && 'id_token' in res.data) {
            this.$store.commit('auth/UPDATE_AUTHENTICATION', res.data)
            this.$store.dispatch('auth/getUserInfo', res.data.access_token).then(user => {
              this.$store.commit('auth/UPDATE_AUTH_USER', user.data)
              this.showToast('success', 'Login Successful', 'You have been successfully logged in', 'BellIcon')
              const nextRoute = this.$route.query.to
              if (nextRoute) {
                this.$router.push(nextRoute)
              } else {
                this.$router.push('/')
              }
            })
          }
        }
      }).catch(err => {
        this.showToast('danger', 'Error', err.message, 'AlertCircleIcon')
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
